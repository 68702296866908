@import "../variables.scss";

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: "Alright Sans Medium";
}

%theme-button {
  background: $color-1;
  border-radius: 100px;
  color: #ffffff;
  padding: 0 25px;
  font-weight: 400;
}

%message-content {
  position: absolute;
  bottom: -8px;
  height: 320px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(0deg, #d0d0d0 52%, transparent 68%);
}

%waves-bg {
  position: absolute;
  bottom: 0;
  height: 320px;
  width: 100%;
  transform: rotate(180deg);

  > span {
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    background-size: 132%;
    background-position: -171px bottom;
    filter: contrast(0.7);
  }
}

.theme-button {
  /* background    : $color-1;
   color         : $color-2;
   border-radius : 30px;
   padding       : 2px 25px;
   font-size     : 15px;
   width         : max-content;*/

  font-size: 13px;
  background: $color-1;
  color: $color-2;
  font-family: Alright Sans Medium;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  border-radius: 50px;
  text-transform: capitalize;
  transition: all 0.2s;
  cursor: pointer;

  &.orange-bg {
    color: #ffffff;

    &:hover {
      color: $color-2;
    }
  }
}

.theme-button-1 {
  background: $color-2;
  color: $color-1;
  border-radius: 30px;
  padding: 2px 25px;
  font-size: 15px;
  width: max-content;
}

.theme-button-2 {
  color: $color-1;
  border: 1px solid;
  border-radius: 30px;
  padding: 2px 25px;
  font-size: 15px;
  width: max-content;
}

/*lets-start*/
%lets-start {
  .let-start {
    display: flex;
    flex-direction: column;

    .lets-wave {
      display: flex;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .lets-content {
      background-color: #cacecf;
      background-image: url("../../../assets/images/Lets_get_started_Transparent.png");
      background-position: 0 bottom;
      background-repeat: no-repeat;
      background-size: cover;
      height: 376px;
      display: flex;

      .lets-col {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 10px;

        h3 {
          font-family: "Alright Sans light";
          padding: 10px 0 40px;
          font-size: 40px;
          font-weight: normal;
          line-height: 1.2;
          margin: 0;
          @media(max-width: 767px){
            h3{
              font-size: 30px;
            }
          }
        }

        .lets-title-text {
          padding: 10px 0 35px;
          font-size: 30px;
          font-family: "Alright Sans Regular";
          line-height: 1.2;
          @media(max-width: 767px){
            font-size: 18px;
          }
        }

        .lets-button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

//venky

//button-start
.button-sm-theme-1 {
  padding: 14px 23px;
  line-height: 11px;
  font-size: 13px;
  background: $color-1;
  color: $color-2;
  font-family: Alright Sans Medium;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  border-radius: 50px;
  text-transform: none;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: $color-2;
    color: $color-1;
  }
}

.button-md-theme-1 {
  padding: 11px 23px;
  line-height: 17px;
  font-size: 13px;
}

.button-sm-theme-2 {
  background: $color-2;
  color: $color-1;
  font-family: Alright Sans Medium;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  border-radius: 50px;
  text-transform: none;
  transition: all 0.2s;
  cursor: pointer;
  padding: 14px 23px;
  line-height: 19px;
  font-size: 13px;

  &:hover {
    background: $color-1;
    color: $color-2;
  }
}

.button-md-theme-1 {
  padding: 11px 23px;
  line-height: 17px;
  font-size: 16px;
}

//button-end

//section-title-start
%section-title {
  .content-top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 2;
    padding: 40px 0 16px;
    margin: 60px 0 50px;
    background: url("#{$orange-Flower}") no-repeat center;
    background-size: contain !important;
    background-repeat: no-repeat;
    @media(max-width: 421px){//added-keerthika
      padding: 40px 0;
      margin: 60px 0;
    }
    .content-title {
      font-family: "Alright Sans Light";
      font-weight: 300;
      position: relative;
      font-size: 35px;
      color: $color-1;
      z-index: 1;
      @media(max-width: 767px){
        padding: 0 ;
        font-size: 25px;//changed 30px - keerthika
      }

      .content-ball {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        display: flex;
        position: absolute;
        z-index: -1;
        left: 3%;
        transform: translateX(-50%);
        top: 0;
        @media(max-width: 767px){
          left: -10px;
          top: -5px;
          transform: none;
        }
        @media(max-width: 421px){//added-keerthika
          top: -8px;
        }
        &.theme-orange {
          background: #ffb077;
        }

        &.theme-white {
          background: #ffffff;
        }
      }
    }

    &.white-flower {
      //filter: brightness(172%) sepia(1) contrast(105%);
      background: url("#{$white-Flower}") no-repeat center;
    }
  }
}

//section-title-end

//global ng-deep
::ng-deep {
  swiper {
    .swiper-button-next,
    .swiper-button-prev {
      margin: 0 16px;

      &:after {
        font-family: "icomoon";
      }
    }

    .swiper-button-next {
      &::after {
        content: "\e901" !important;
      }
    }

    .swiper-button-prev {
      &::after {
        content: "\e900" !important;
      }
    }
  }

  .mat-form-field {
    font-family: "Alright Sans Regular", Arial, Helvetica, sans-serif;
  }
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
  line-height: 2;
}
