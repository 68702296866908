@font-face {
	font-family : 'Alright Sans Regular';
	src         : local('Alright Sans Regular'), local('Alright Sans - Regular'), url('../fonts/AlrightSansRegular.otf') format('opentype');
	font-weight : 400;
	font-style  : normal;
}

@font-face {
	font-family : 'Alright Sans Light';
	src         : local('Alright Sans Light'), local('Alright Sans - Light'), url('../fonts/AlrightSansLight.otf') format('opentype');
	font-weight : 100;
	font-display: swap;
	font-style  : normal;
}

@font-face {
	font-family : 'Alright Sans Medium';
	src         : local('Alright Sans Medium'), local('Alright Sans - Medium'), url('../fonts/AlrightSansMedium.otf') format('opentype');
	font-weight : 500;
	font-display: swap;
	font-style  : normal;
}

@font-face {
	font-family : 'Alright Sans Bold';
	src         : local('Alright Sans Bold'), local('Alright Sans - Bold'), url('../fonts/AlrightSansBold.otf') format('opentype');
	font-weight : 900;
	font-display: swap;
	font-style  : normal;
}


@font-face {
	font-family : 'Cagile';
	src         : local('Cagile Regular'), local('Cagile - Regular'), url('../fonts/CagileRegular.ttf') format('truetype');
	font-weight : 400;
	font-display: swap;
	font-style  : normal;
}


@font-face {
	font-family : 'Source Sans Pro';
	src         : local('Source Sans Pro'), local('Source Sans Pro - Regular'), url('../fonts/SourceSansPro-Regular.otf') format('opentype');
	font-weight : 400;
	font-display: swap;
	font-style  : normal;
}


@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon/icomoon.eot?cjezr6');
	src:  url('../fonts/icomoon/icomoon.eot?cjezr6#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon/icomoon.ttf?4i901') format('truetype'),
	  url('../fonts/icomoon/icomoon.woff?4i901') format('woff'),
	  url('../fonts/icomoon/icomoon.svg?4i901#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

.icon-Arrow-Left:before {
  content: "\e900";
}
.icon-Arrow-Right:before {
  content: "\e901";
}
