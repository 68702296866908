@-ms-viewport {
	width : device-width;
}

html {
	-webkit-box-sizing : border-box;
	box-sizing         : border-box;
	-ms-overflow-style : scrollbar;
}

*,
*::before,
*::after {
	-webkit-box-sizing : inherit;
	box-sizing         : inherit;
}

.row {
	display           : -webkit-box;
	display           : -webkit-flex;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-flex-wrap : wrap;
	-ms-flex-wrap     : wrap;
	flex-wrap         : wrap;
/*	margin-right      : -15px;
	margin-left       : -15px;*/
}

.no-padding {
	padding : 0 !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	position      : relative;
	width         : 100%;
	padding-right : 15px;
	padding-left  : 15px;
}

.col {
	-webkit-flex-basis      : 0;
	-ms-flex-preferred-size : 0;
	flex-basis              : 0;
	-webkit-box-flex        : 1;
	-webkit-flex-grow       : 1;
	-ms-flex-positive       : 1;
	flex-grow               : 1;
	max-width               : 100%;
}

.col-auto {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 auto;
	-ms-flex         : 0 0 auto;
	flex             : 0 0 auto;
	width            : auto;
	max-width        : 100%;
}

.col-1 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 8.3333333333%;
	-ms-flex         : 0 0 8.3333333333%;
	flex             : 0 0 8.3333333333%;
	max-width        : 8.3333333333%;
}

.col-2 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 16.6666666667%;
	-ms-flex         : 0 0 16.6666666667%;
	flex             : 0 0 16.6666666667%;
	max-width        : 16.6666666667%;
}

.col-3 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 25%;
	-ms-flex         : 0 0 25%;
	flex             : 0 0 25%;
	max-width        : 25%;
}

.col-4 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 33.3333333333%;
	-ms-flex         : 0 0 33.3333333333%;
	flex             : 0 0 33.3333333333%;
	max-width        : 33.3333333333%;
}

.col-5 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 41.6666666667%;
	-ms-flex         : 0 0 41.6666666667%;
	flex             : 0 0 41.6666666667%;
	max-width        : 41.6666666667%;
}

.col-6 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 50%;
	-ms-flex         : 0 0 50%;
	flex             : 0 0 50%;
	max-width        : 50%;
}

.col-7 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 58.3333333333%;
	-ms-flex         : 0 0 58.3333333333%;
	flex             : 0 0 58.3333333333%;
	max-width        : 58.3333333333%;
}

.col-8 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 66.6666666667%;
	-ms-flex         : 0 0 66.6666666667%;
	flex             : 0 0 66.6666666667%;
	max-width        : 66.6666666667%;
}

.col-9 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 75%;
	-ms-flex         : 0 0 75%;
	flex             : 0 0 75%;
	max-width        : 75%;
}

.col-10 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 83.3333333333%;
	-ms-flex         : 0 0 83.3333333333%;
	flex             : 0 0 83.3333333333%;
	max-width        : 83.3333333333%;
}

.col-11 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 91.6666666667%;
	-ms-flex         : 0 0 91.6666666667%;
	flex             : 0 0 91.6666666667%;
	max-width        : 91.6666666667%;
}

.col-12 {
	-webkit-box-flex : 0;
	-webkit-flex     : 0 0 100%;
	-ms-flex         : 0 0 100%;
	flex             : 0 0 100%;
	max-width        : 100%;
}


@media (min-width : 480px) {
	.col-sm {
		-webkit-flex-basis      : 0;
		-ms-flex-preferred-size : 0;
		flex-basis              : 0;
		-webkit-box-flex        : 1;
		-webkit-flex-grow       : 1;
		-ms-flex-positive       : 1;
		flex-grow               : 1;
		max-width               : 100%;
	}

	.col-sm-auto {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 auto;
		-ms-flex         : 0 0 auto;
		flex             : 0 0 auto;
		width            : auto;
		max-width        : 100%;
	}

	.col-sm-1 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 8.3333333333%;
		-ms-flex         : 0 0 8.3333333333%;
		flex             : 0 0 8.3333333333%;
		max-width        : 8.3333333333%;
	}

	.col-sm-2 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 16.6666666667%;
		-ms-flex         : 0 0 16.6666666667%;
		flex             : 0 0 16.6666666667%;
		max-width        : 16.6666666667%;
	}

	.col-sm-3 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 25%;
		-ms-flex         : 0 0 25%;
		flex             : 0 0 25%;
		max-width        : 25%;
	}

	.col-sm-4 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 33.3333333333%;
		-ms-flex         : 0 0 33.3333333333%;
		flex             : 0 0 33.3333333333%;
		max-width        : 33.3333333333%;
	}

	.col-sm-5 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 41.6666666667%;
		-ms-flex         : 0 0 41.6666666667%;
		flex             : 0 0 41.6666666667%;
		max-width        : 41.6666666667%;
	}

	.col-sm-6 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 50%;
		-ms-flex         : 0 0 50%;
		flex             : 0 0 50%;
		max-width        : 50%;
	}

	.col-sm-7 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 58.3333333333%;
		-ms-flex         : 0 0 58.3333333333%;
		flex             : 0 0 58.3333333333%;
		max-width        : 58.3333333333%;
	}

	.col-sm-8 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 66.6666666667%;
		-ms-flex         : 0 0 66.6666666667%;
		flex             : 0 0 66.6666666667%;
		max-width        : 66.6666666667%;
	}

	.col-sm-9 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 75%;
		-ms-flex         : 0 0 75%;
		flex             : 0 0 75%;
		max-width        : 75%;
	}

	.col-sm-10 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 83.3333333333%;
		-ms-flex         : 0 0 83.3333333333%;
		flex             : 0 0 83.3333333333%;
		max-width        : 83.3333333333%;
	}

	.col-sm-11 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 91.6666666667%;
		-ms-flex         : 0 0 91.6666666667%;
		flex             : 0 0 91.6666666667%;
		max-width        : 91.6666666667%;
	}

	.col-sm-12 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 100%;
		-ms-flex         : 0 0 100%;
		flex             : 0 0 100%;
		max-width        : 100%;
	}

}

@media (min-width : 768px) {
	.col-md {
		-webkit-flex-basis      : 0;
		-ms-flex-preferred-size : 0;
		flex-basis              : 0;
		-webkit-box-flex        : 1;
		-webkit-flex-grow       : 1;
		-ms-flex-positive       : 1;
		flex-grow               : 1;
		max-width               : 100%;
	}

	.col-md-auto {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 auto;
		-ms-flex         : 0 0 auto;
		flex             : 0 0 auto;
		width            : auto;
		max-width        : 100%;
	}

	.col-md-1 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 8.3333333333%;
		-ms-flex         : 0 0 8.3333333333%;
		flex             : 0 0 8.3333333333%;
		max-width        : 8.3333333333%;
	}

	.col-md-2 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 16.6666666667%;
		-ms-flex         : 0 0 16.6666666667%;
		flex             : 0 0 16.6666666667%;
		max-width        : 16.6666666667%;
	}

	.col-md-3 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 25%;
		-ms-flex         : 0 0 25%;
		flex             : 0 0 25%;
		max-width        : 25%;
	}

	.col-md-4 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 33.3333333333%;
		-ms-flex         : 0 0 33.3333333333%;
		flex             : 0 0 33.3333333333%;
		max-width        : 33.3333333333%;
	}

	.col-md-5 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 41.6666666667%;
		-ms-flex         : 0 0 41.6666666667%;
		flex             : 0 0 41.6666666667%;
		max-width        : 41.6666666667%;
	}

	.col-md-6 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 50%;
		-ms-flex         : 0 0 50%;
		flex             : 0 0 50%;
		max-width        : 50%;
	}

	.col-md-7 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 58.3333333333%;
		-ms-flex         : 0 0 58.3333333333%;
		flex             : 0 0 58.3333333333%;
		max-width        : 58.3333333333%;
	}

	.col-md-8 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 66.6666666667%;
		-ms-flex         : 0 0 66.6666666667%;
		flex             : 0 0 66.6666666667%;
		max-width        : 66.6666666667%;
	}

	.col-md-9 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 75%;
		-ms-flex         : 0 0 75%;
		flex             : 0 0 75%;
		max-width        : 75%;
	}

	.col-md-10 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 83.3333333333%;
		-ms-flex         : 0 0 83.3333333333%;
		flex             : 0 0 83.3333333333%;
		max-width        : 83.3333333333%;
	}

	.col-md-11 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 91.6666666667%;
		-ms-flex         : 0 0 91.6666666667%;
		flex             : 0 0 91.6666666667%;
		max-width        : 91.6666666667%;
	}

	.col-md-12 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 100%;
		-ms-flex         : 0 0 100%;
		flex             : 0 0 100%;
		max-width        : 100%;
	}

}

@media (min-width : 992px) {
	.col-lg {
		-webkit-flex-basis      : 0;
		-ms-flex-preferred-size : 0;
		flex-basis              : 0;
		-webkit-box-flex        : 1;
		-webkit-flex-grow       : 1;
		-ms-flex-positive       : 1;
		flex-grow               : 1;
		max-width               : 100%;
	}

	.col-lg-auto {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 auto;
		-ms-flex         : 0 0 auto;
		flex             : 0 0 auto;
		width            : auto;
		max-width        : 100%;
	}

	.col-lg-1 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 8.3333333333%;
		-ms-flex         : 0 0 8.3333333333%;
		flex             : 0 0 8.3333333333%;
		max-width        : 8.3333333333%;
	}

	.col-lg-2 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 16.6666666667%;
		-ms-flex         : 0 0 16.6666666667%;
		flex             : 0 0 16.6666666667%;
		max-width        : 16.6666666667%;
	}

	.col-lg-3 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 25%;
		-ms-flex         : 0 0 25%;
		flex             : 0 0 25%;
		max-width        : 25%;
	}

	.col-lg-4 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 33.3333333333%;
		-ms-flex         : 0 0 33.3333333333%;
		flex             : 0 0 33.3333333333%;
		max-width        : 33.3333333333%;
	}

	.col-lg-5 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 41.6666666667%;
		-ms-flex         : 0 0 41.6666666667%;
		flex             : 0 0 41.6666666667%;
		max-width        : 41.6666666667%;
	}

	.col-lg-6 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 50%;
		-ms-flex         : 0 0 50%;
		flex             : 0 0 50%;
		max-width        : 50%;
	}

	.col-lg-7 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 58.3333333333%;
		-ms-flex         : 0 0 58.3333333333%;
		flex             : 0 0 58.3333333333%;
		max-width        : 58.3333333333%;
	}

	.col-lg-8 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 66.6666666667%;
		-ms-flex         : 0 0 66.6666666667%;
		flex             : 0 0 66.6666666667%;
		max-width        : 66.6666666667%;
	}

	.col-lg-9 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 75%;
		-ms-flex         : 0 0 75%;
		flex             : 0 0 75%;
		max-width        : 75%;
	}

	.col-lg-10 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 83.3333333333%;
		-ms-flex         : 0 0 83.3333333333%;
		flex             : 0 0 83.3333333333%;
		max-width        : 83.3333333333%;
	}

	.col-lg-11 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 91.6666666667%;
		-ms-flex         : 0 0 91.6666666667%;
		flex             : 0 0 91.6666666667%;
		max-width        : 91.6666666667%;
	}

	.col-lg-12 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 100%;
		-ms-flex         : 0 0 100%;
		flex             : 0 0 100%;
		max-width        : 100%;
	}

}

@media (min-width : 1200px) {
	.col-xl {
		-webkit-flex-basis      : 0;
		-ms-flex-preferred-size : 0;
		flex-basis              : 0;
		-webkit-box-flex        : 1;
		-webkit-flex-grow       : 1;
		-ms-flex-positive       : 1;
		flex-grow               : 1;
		max-width               : 100%;
	}

	.col-xl-auto {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 auto;
		-ms-flex         : 0 0 auto;
		flex             : 0 0 auto;
		width            : auto;
		max-width        : 100%;
	}

	.col-xl-1 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 8.3333333333%;
		-ms-flex         : 0 0 8.3333333333%;
		flex             : 0 0 8.3333333333%;
		max-width        : 8.3333333333%;
	}

	.col-xl-2 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 16.6666666667%;
		-ms-flex         : 0 0 16.6666666667%;
		flex             : 0 0 16.6666666667%;
		max-width        : 16.6666666667%;
	}

	.col-xl-3 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 25%;
		-ms-flex         : 0 0 25%;
		flex             : 0 0 25%;
		max-width        : 25%;
	}

	.col-xl-4 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 33.3333333333%;
		-ms-flex         : 0 0 33.3333333333%;
		flex             : 0 0 33.3333333333%;
		max-width        : 33.3333333333%;
	}

	.col-xl-5 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 41.6666666667%;
		-ms-flex         : 0 0 41.6666666667%;
		flex             : 0 0 41.6666666667%;
		max-width        : 41.6666666667%;
	}

	.col-xl-6 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 50%;
		-ms-flex         : 0 0 50%;
		flex             : 0 0 50%;
		max-width        : 50%;
	}

	.col-xl-7 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 58.3333333333%;
		-ms-flex         : 0 0 58.3333333333%;
		flex             : 0 0 58.3333333333%;
		max-width        : 58.3333333333%;
	}

	.col-xl-8 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 66.6666666667%;
		-ms-flex         : 0 0 66.6666666667%;
		flex             : 0 0 66.6666666667%;
		max-width        : 66.6666666667%;
	}

	.col-xl-9 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 75%;
		-ms-flex         : 0 0 75%;
		flex             : 0 0 75%;
		max-width        : 75%;
	}

	.col-xl-10 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 83.3333333333%;
		-ms-flex         : 0 0 83.3333333333%;
		flex             : 0 0 83.3333333333%;
		max-width        : 83.3333333333%;
	}

	.col-xl-11 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 91.6666666667%;
		-ms-flex         : 0 0 91.6666666667%;
		flex             : 0 0 91.6666666667%;
		max-width        : 91.6666666667%;
	}

	.col-xl-12 {
		-webkit-box-flex : 0;
		-webkit-flex     : 0 0 100%;
		-ms-flex         : 0 0 100%;
		flex             : 0 0 100%;
		max-width        : 100%;
	}
}


