// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import "./src/assets/styles/variables";

$omamori-color-2: (
  50: $color-2,
  100: $color-2,
  200: $color-2,
  300: $color-2,
  400: $color-2,
  500: $color-2,
  600: $color-2,
  700: $color-2,
  800: $color-2,
  900: $color-2,
  A100: $color-2,
  A200: $color-2,
  A400: $color-2,
  A700: $color-2,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: black,
  ),
);

$omamori-client-primary: mat-palette($mat-indigo);
$omamori-client-accent: mat-palette($omamori-color-2, A200, A100, A400);

// The warn palette is optional (defaults to red).
$omamori-client-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$omamori-client-theme: mat-light-theme((color: (primary: $omamori-client-primary,
        accent: $omamori-client-accent,
        warn: $omamori-client-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($omamori-client-theme);

/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/globals";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Alright Sans Regular";
  //font-family   : Roboto, "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 0;
  // @media(max-width: 767px){ commented - keerthika
  //   padding-bottom: 78px;
  // }
}

//subscribe-popup.html
.subscribe-popup-container {
  mat-dialog-container {
    padding: 0;
    border-radius: 15px;
  }

  @media(max-width: 420px) {
    //added-keerthika
    max-width: 50vh !important;
  }
}

//rating-reviews.html
.rating-review-container {
  mat-dialog-container {
    padding: 0;
    border-radius: 20px;
  }
}

.teal-mat-bg {
  background-color: #022b37;
}

.amber-dark-bg {
  background-color: #deb37d;
}

.grey-light-bg {
  background-color: #737377;
}

.black-bg {
  background-color: #000000;
}

input,
// .mat-select-placeholder,
textarea {
  font-family: "Alright Sans Regular", Arial, Helvetica, sans-serif !important;
}

.mat-option.mat-selected {
  color: #ffb076 !important;
}

.app-screen-web {
  .show-hide {
    display: none !important;
  }
  .close-circle{
    display: none;
  }
}
.app-screen-mobile {
  #web-nav {
    display: none;
  }
  .show-hide {
    display:block !important;
  }
  .mob-sidebar {
    height: 100%;
    background-color: #ffffff;
    z-index: 6;
    position: sticky !important;
    font-size: 20px;

    a {
      color: rgba(0, 0, 0, 0.5) !important;
      cursor: pointer;
      &.active{
        color: rgba(0, 0, 0, 0.9) !important;
      }
    }
    
  }

  .name-text {
    font-size: 45px !important;
  }

  .getting-married,
  .month-date {
    font-size: 25px !important;
  }

  nav {
    display: flex !important;
    flex-direction: column !important;
    .container,
    .navbar-row-div{
      padding-left: 0% !important;
      padding-right: 0% !important;
    }
    .nav-class-col-6{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .banner {
    .banner-center-text {
      top:unset !important;
      width: 90% !important;
      margin-top: 25px;
      bottom:15% !important;
    }
  }

  .info-card-bride,
  .info-card-groom {
    text-align: center !important;

    h5 {
      padding: 0% !important;
    }

    p {
      padding: 0% !important;
    }
  }

  .center-and {
    // top: 14% !important;
    // height: 912px !important;
    display: none !important;
  }

  .paragraph {
    padding: 0% !important;
    line-height: unset !important;
  }

  .registry-row {
    flex-wrap: nowrap !important;
    max-width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;

    form {
      max-width: 100% !important;

      .content-header {
        max-width: 100% !important;
      }
    }
  }

  .grid-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  }

  .row {
    // max-width: 100% !important;
    flex-direction: column !important;

    [class*="col-"] {
      max-width: 100%;
    }

    .d2-nav {
      display: flex;
      flex-direction: column;
      .logo-ham-menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .card-parent {
      margin-left: 0% !important;
      max-width: 95% !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      min-height: 525px !important;

      .title {
        padding: 48px 0% !important;

        form {
          width: 100% !important;
          padding: 0px 40px;

          select {
            padding: 0px !important;
          }
        }
      }
    }
  }

  .d1-row {
    max-width: 100% !important;
    .banner-card{
      bottom: -30% !important;
    }
    .name-h1 {
      font-size: 37px !important;
    }
  }

  #mob-ham {
    display: none;
  }
  #d4-rsvp,
  #d5-rsvp {
    .banner-card {
      bottom: -125% !important;
    }
  }

  .d2-round-about-img {
    width: 100% !important;
    height: 360px !important;
  }

  .our-story-row,
  footer {
    display: flex;
    flex-direction: row;
  }

  .our-story-row :nth-child(1) {
    order: 1;
  }

  .our-story-row :nth-child(2) {
    order: 3;
  }

  .our-story-row :nth-child(3) {
    order: 2;
  }

  .our-story-row :nth-child(4) {
    order: 4;
  }

  .our-story-row :nth-child(5) {
    order: 5;
  }

  #d2-couples-container {
    .our-story-row :nth-child(1) {
      order: 1;
    }

    .our-story-row :nth-child(2) {
      order: 2;
    }

    .our-story-row :nth-child(3) {
      order: 3;
    }

    .our-story-row :nth-child(4) {
      order: 5;
    }

    .our-story-row :nth-child(5) {
      order: 4;
    }
  }

  .footer {
    display: flex !important;
    flex-direction: column;
    align-items: center;

    [class*="col-"] {
      max-width: 100%;
      text-align: center !important;
    }
  }

  .footer :nth-child(1) {
    order: 2;
  }

  .footer :nth-child(2) {
    order: 1;
  }

  .footer :nth-child(3) {
    order: 4;
  }

  .footer :nth-child(4) {
    order: 3;
  }

}

.swiper-pagination {
  .swiper-pagination-bullet {
    box-sizing: content-box;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
    transition: opacity 0.3s ease;
    width: 7px;
    height: 7px;
    border: 2px solid #fff !important;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: #ffb076 !important;
    }
  }
}

mat-checkbox {
  display: block;
}

.mat-form-field-infix {
  border: 0 !important;
}

.mat-form-field-flex {
  align-items: center !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-icon-button {
  margin-top: -20px;
  padding-right: 10px;
}

// .mat-form-field-label-wrapper {
//   left: 15px !important;
//   top: auto;
// }
// .dummy {
//   height: 136px;
//   background-color: #fff;
//   width: 100%;
// }
// app-main-header {
//   position: absolute;
//   z-index: 5;
// }