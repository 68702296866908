@media all and (max-width: 1199px) {
    swiper {
        .swiper-container {
            .swiper-wrapper {
                .swiper-slide {
                    .st-logo {
                        width: auto !important;
                        max-width: 80vw;
                    }
                }
            }
        }
    }
}