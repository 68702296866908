@for $i from 1 through 100 {
	$val: $i * 1;
	.flex-#{$val}{
	    width: #{$val} + '%';
	}
}


@for $i from 1 through 20 {
    .mi-#{$i * 8} {
        margin : #{($i * 8) + 'px'} !important;
    }

    .mti-#{$i * 8} {
        margin-top : #{($i * 8) + 'px'} !important;
    }

    .mri-#{$i * 8} {
        margin-right : #{($i * 8) + 'px'} !important;
    }

    .mbi-#{$i * 8} {
        margin-bottom : #{($i * 8) + 'px'} !important;
    }

    .mli-#{$i * 8} {
        margin-left : #{($i * 8) + 'px'} !important;
    }

    .mi-#{$i * 5} {
        margin : #{($i * 5) + 'px'} !important;
    }

    .mti-#{$i * 5} {
        margin-top : #{($i * 5) + 'px'} !important;
    }

    .mri-#{$i * 5} {
        margin-right : #{($i * 5) + 'px'} !important;
    }

    .mbi-#{$i * 5} {
        margin-bottom : #{($i * 5) + 'px'} !important;
    }

    .mli-#{$i * 5} {
        margin-left : #{($i * 5) + 'px'} !important;
    }
}

@for $i from 1 through 20 {
  .pi-#{$i * 8} {
    margin : #{($i * 8) + 'px'} !important;
  }

  .pti-#{$i * 8} {
    margin-top : #{($i * 8) + 'px'} !important;
  }

  .pri-#{$i * 8} {
    margin-right : #{($i * 8) + 'px'} !important;
  }

  .pbi-#{$i * 8} {
    margin-bottom : #{($i * 8) + 'px'} !important;
  }

  .pli-#{$i * 8} {
    margin-left : #{($i * 8) + 'px'} !important;
  }

  .pi-#{$i * 5} {
    margin : #{($i * 5) + 'px'} !important;
  }

  .pti-#{$i * 5} {
    margin-top : #{($i * 5) + 'px'} !important;
  }

  .pri-#{$i * 5} {
    margin-right : #{($i * 5) + 'px'} !important;
  }

  .pbi-#{$i * 5} {
    margin-bottom : #{($i * 5) + 'px'} !important;
  }

  .pli-#{$i * 5} {
    margin-left : #{($i * 5) + 'px'} !important;
  }
}

@for $i from 1 through 50 {
    .fs-#{$i * 1} {
        font-size: #{($i * 1) + 'px'} !important;
    }
}
