.dialog_outer {
  margin: auto !important;
  max-height: 90% !important;
  max-width: 850px !important;
  min-height: 70%;
  min-width: 850px;
  @media all and (max-width: 420px) {//added - keerthika
    min-width: 90% !important;
    min-height: 90% !important;
  }
  mat-dialog-container {
    height: auto !important;
    padding: 0;
    border-radius: 15px;
  }

  @media all and (max-width: 1024px) {
    max-width: 80% !important;
    min-width: 80%;
  }
}

#cdk-overlay-0 {
  @media all and (max-height: 630px) {
    // zoom: 0.7;
  }
}
