input {
  outline : 0;
}

.no-shadow {
  box-shadow : none !important;
}

.no-border {
  border : 0;
}

.no-margin {
  margin : 0 !important;
}

.text-bold {
  font-weight : bold !important;
}

.text-center {
  text-align : center;
}

.theme-color-1 {
  color : $color-1;
}

.theme-color-2 {
  color : $color-2;
}

.layout-row {
  display        : flex;
  flex-direction : row;
}

.layout-column {
  display        : flex;
  flex-direction : column;
}

.link {
  text-decoration : underline;
  cursor          : pointer;
}

body {
  line-height : 1.8;
}

a {
  text-decoration : none;
}

ul {
  list-style : none;
  padding    : 0;
  margin     : 0;
}

//Grid-layout
.row {
  max-width   : 1200px;
  margin      : auto;
  align-items : center;
  @media(max-width: 767px){
    max-width: 100%;
  }
}

//nav-menu
.sub-menu {
  left             : 0;
  z-index          : 1;
  transition       : opacity .2s ease-in;
  width            : 200px;
  background-color : #FFFFFF;

  li {
    position : relative;
    cursor   : pointer;
    margin   : 0;
    display  : flex;

    a {
      font-family         : "Alright Sans Medium", Arial, Helvetica, sans-serif;
      font-weight         : 400;
      padding             : 12px 20px;
      border-bottom-color : #E2E2E2;
      color               : #212934;
      font-size           : 14px;
      height              : auto;
      line-height         : 1.8;

      &:hover {
        color  : #FFB077;
        cursor : pointer;
      }
    }

  }
}

.v-hidden {
  visibility : hidden;
}

.pointer {
  cursor : pointer;
}
