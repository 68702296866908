.mat-button {
  padding-top : 2px !important;

  &.theme-button,
  &.theme-button-1,
  &.theme-button-2 {
    border-radius  : 30px;
    padding        : 0 25px;
    font-size      : 13px;
    width          : max-content;
    min-width      : 130px;
    text-transform : capitalize;
    font-weight    : 100;
  }

  &.theme-button {
    background     : $color-1;
    color          : $color-2;
    padding-top    : 2px;
  }

  &.theme-button-1 {
    background : $color-2;
    color      : $color-1;
  }

  &.theme-button-2 {
    color  : $color-1;
    border : 1px solid;
  }
}
